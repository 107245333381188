import React from 'react';

export function Close(props: React.SVGAttributes<{}>) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect width="40" height="40" rx="20" fill="#D9F3FF"/>
            <path d="M12 12L29 29" stroke="#393939" strokeLinecap="round"/>
            <path d="M12 29L29 12" stroke="#393939" strokeLinecap="round"/>
        </svg>

    );
}
