import "@app/assets/css/globals.css";
import type {AppProps} from "next/app";
import {CacheProvider, EmotionCache} from "@emotion/react";
import {NextPageWithLayout} from "@app/types";
import createEmotionCache from "@app/configs/createEmotionCache";
import {ThemeProvider} from "@mui/material";
import {lightTheme} from "@app/configs/muiTheme";
import React from "react";
import NextNProgress from "@app/components/ui/nprogress";
import {ToastContainer} from "react-toastify";
import ModalContainer from "@app/components/modal-views/container";
import globalConstants from "@app/constants/global";
import {NextSeo} from "next-seo";

const clientSideEmotionCache = createEmotionCache();
type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
    emotionCache?: EmotionCache;
    pageProps: any;
};
export default function App({Component, pageProps, router, emotionCache = clientSideEmotionCache}: AppPropsWithLayout) {
    const getLayout = Component.getLayout ?? ((page: any) => page);
    // default
    let title = globalConstants.title || globalConstants.socialPreview.title;
    let description = globalConstants.socialPreview.desc;
    let url = globalConstants.socialPreview.url;
    let imageUrl = globalConstants.socialPreview.image;
    return (
        <ThemeProvider theme={lightTheme}>
            <CacheProvider value={emotionCache}>
                <NextSeo
                    title={title || globalConstants.socialPreview.title}
                    description={description}
                    // noindex={!environments.IS_IN_PRODUCTION_MODE}
                    // nofollow={!environments.IS_IN_PRODUCTION_MODE}
                    openGraph={{
                        type: 'website',
                        locale: 'en_IE',
                        url,
                        site_name: title || globalConstants.appName,
                        description: description,
                        title,
                        images: [
                            {
                                url: imageUrl,
                                alt: title ?? 'Manoj Rai'
                            }
                        ]
                    }}
                    twitter={{
                        handle: globalConstants.twitterHandle,
                        site: url,
                        cardType: 'summary_large_image'
                    }}
                />
                <NextNProgress color="#0764EB" startPosition={0} stopDelayMs={400} height={2}
                               options={{easing: 'ease'}}/>
                <ToastContainer position="bottom-center" autoClose={3000} hideProgressBar newestOnTop closeOnClick
                                rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover={false} theme="light"/>
                <ModalContainer/>
                <Component {...pageProps} key={router.asPath}/>
            </CacheProvider>
        </ThemeProvider>
    );
}
