import environments from '@app/configs/environments';

const globalConstants = {
    appName: 'Manoj Portfolio',
    appDesc: 'A self-taught, Kathmandu based UI/UX designer that enjoys making challenging situations fun. I’m aiming to be the most brilliant designer of my time.',
    title: 'Manoj Rai',
    favIcon: 'favIcon',
    twitterHandle: '@notmanoj',
    titleImg: 'titleImg',
    socialPreview: {
        url: `https://www.manojrai.me`,
        title: 'Manoj Rai',
        desc: 'A self-taught, Kathmandu based UI/UX designer that enjoys making challenging situations fun. I’m aiming to be the most brilliant designer of my time.',
        image: `https://notmanoj.github.io/bucket/portfolio/images/me.png`
    },
    consoleWarningTitle: `%cStop!`,
    consoleWarningDescription: `%cThis is a browser feature intended for developers. If someone told you to copy-paste something here to enable a feature, it is a scam and will give them access to your sensitive information.`,
    imageTypes: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/svg+xml']
};

export default globalConstants;
