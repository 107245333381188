import React from 'react';
import {Close} from "@app/components/icons/close";
import Smile from "@app/components/icons/smile";
import FactEmoji from "@app/components/icons/fact-emoji";
import {getRandomStringFromArray} from "@app/utils/stringUtils";
import {useModal} from "@app/components/modal-views/context";

function RandomFactModal({facts}: { facts: Array<string> }) {
    const {closeModal} = useModal();
    return (
        <div
            className="flex relative max-w-[713px] overflow-hidden w-full p-10 bg-modalbg flex-col justify-center gap-10 rounded-[16px]">
            <Close onClick={closeModal} className="absolute cursor-pointer right-4 top-4"/>
            <div className="flex items-center gap-2">
                <Smile/>
                <p className="h3 text-black-100">Thank you for scrolling up to here</p>
            </div>
            <p className="h1Responsive text-black-100">
                🐈 Curiosity kills the cat, but you will learn things!
            </p>
            <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                    <FactEmoji/>
                    <p className="h3 text-black-100">RANDOM FACT</p>
                </div>
                <p className="p1Responsive text-black-200">{getRandomStringFromArray(facts)}</p>
            </div>
        </div>
    );
}

export default RandomFactModal;