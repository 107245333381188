import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';

import cn from 'classnames';

import ButtonDrip from '@app/components/ui/button/button-drip';
import ButtonLoader from '@app/components/ui/button/button-loader';
import {LoaderSizeTypes, LoaderVariantTypes} from '@app/components/ui/loader';

type ShapeNames = 'rounded' | 'pill' | 'circle';
type VariantNames = 'ghost' | 'solid' | 'transparent' | 'outline';
type ColorNames = 'primary' | 'white' | 'gray' | 'success' | 'info' | 'warning' | 'danger';
type SizeNames = 'large' | 'medium' | 'small' | 'extraSmall';

const shapes: Record<ShapeNames, string[]> = {
    rounded: ['rounded-[40px]'],
    pill: ['rounded-full'],
    circle: ['rounded-full']
};
const variants: Record<VariantNames, string[]> = {
    ghost: ['bg-transparent', 'p-2'],
    solid: ['text-white'],
    transparent: ['bg-transparent hover:bg-gray-50'],
    outline: ['!text-brand-2 font-medium !bg-white-2 !border-brand-2 border hover:!text-brand-2 hover:!bg-brand-4']
};
const colors: Record<ColorNames, string[]> = {
    primary: ['text-white-2', 'bg-brand-2 hover:bg-brand-1'],
    white: ['text-gray-900', 'bg-white focus:ring-white', 'border-white'],
    gray: ['!text-black-1', '!bg-white-1', 'hover:!bg-white-4'],
    success: ['text-green-500', 'bg-green-500 hover:bg-green-200 focus:ring-green-500', 'border-green-500'],
    info: ['text-blue-500', 'bg-blue-500 hover:bg-blue-200 focus:ring-blue-500', 'border-blue-500'],
    warning: ['text-yellow-500', 'bg-yellow-500 hover:bg-yellow-200 focus:ring-yellow-500', 'border-yellow-500'],
    danger: ['text-white-2', 'bg-red-500 hover:bg-red-600']
};
const sizes: Record<SizeNames, string[]> = {
    large: ['py-6 px-8 h-[63px] buttonText1'],
    medium: ['py-3 px-6 h-[48px] buttonText1'],
    small: ['py-2 px-6 h-[37px] buttonText2'],
    extraSmall: ['py-2 px-4 h-[37px] buttonText3']
};

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    isLoading?: boolean;
    disabled?: boolean;
    shape?: ShapeNames;
    variant?: VariantNames;
    color?: ColorNames;
    size?: SizeNames;
    fullWidth?: boolean;
    loaderSize?: LoaderSizeTypes;
    loaderVariant?: LoaderVariantTypes;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({
         children,
         className,
         isLoading,
         disabled,
         fullWidth,
         shape = 'rounded',
         variant = 'solid',
         color = 'primary',
         size = 'small',
         loaderSize = 'small',
         loaderVariant = 'scaleUp',
         onClick,
         ...buttonProps
     }, ref: React.Ref<HTMLButtonElement | null>) => {
        let [dripShow, setDripShow] = useState<boolean>(false);
        let [dripX, setDripX] = useState<number>(0);
        let [dripY, setDripY] = useState<number>(0);
        const colorClassNames = colors[color];
        const sizeClassNames = sizes[size];
        const buttonRef = useRef<HTMLButtonElement>(null);
        useImperativeHandle(ref, () => buttonRef.current);

        function dripCompletedHandle() {
            setDripShow(false);
            setDripX(0);
            setDripY(0);
        }

        const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
            if (!isLoading && buttonRef.current) {
                const rect = buttonRef.current.getBoundingClientRect();
                setDripShow(true);
                setDripX(event.clientX - rect.left);
                setDripY(event.clientY - rect.top);
            }
            onClick && onClick(event);
        };

        let buttonColorClassNames = '';
        let buttonDripColor = '';

        return (
            <button
                ref={buttonRef}
                onClick={clickHandler}
                className={cn(
                    'relative inline-flex shrink-0 items-center justify-center overflow-hidden text-center transition-all',
                    !disabled ? buttonColorClassNames : 'opacity-50 cursor-not-allowed',
                    isLoading && 'pointer-events-auto cursor-default focus:outline-none',
                    fullWidth && 'w-full',
                    color === 'white' || color === 'gray' ? 'text-gray-900 ' : variants[variant],
                    shapes[shape],
                    colorClassNames,
                    shape === 'circle' ? `${sizeClassNames[1]}` : `${sizeClassNames[0]}`,
                    className
                )}
                disabled={disabled}
                {...buttonProps}
            >
                <span className={cn(isLoading && 'invisible opacity-0')}>{children}</span>

                {isLoading && <ButtonLoader size={loaderSize} variant={loaderVariant}/>}

                {dripShow && <ButtonDrip x={dripX} y={dripY}
                                         color={['white', 'gray'].indexOf(color) !== -1 ? 'rgba(0, 0, 0, 0.1)' : buttonDripColor}
                                         fullWidth={fullWidth} onCompleted={dripCompletedHandle}/>}
            </button>
        );
    }
);

Button.displayName = 'Button';
export default Button;
